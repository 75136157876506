import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";

import { ProjectGridItem } from "../../scenes/projects/ProjectGridItem";
import { GridLayout } from "./GridLayout";
import { ProjectListItem } from "../../scenes/projects/ProjectListItem";
import { ListLayout } from "./ListLayout";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { createElement, useState } from "react";
import { tokens } from "../../theme/theme";
const defaultLayouts = [
  {
    tooltip: "Grid",
    icon: <ViewModuleIcon />,
    Item: ProjectGridItem,
    layout: GridLayout,
  },
  {
    tooltip: "List",
    icon: <ViewListIcon />,
    Item: ProjectListItem,
    layout: ListLayout,
  },
];

export const LayoutShifter = ({
  items,
  label,
  layouts = defaultLayouts,
  header,
  actions = [],
  isLoading,
  onChange = () => {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [currentLayout, setCurrentLayout] = useState(0);
  const handleLayoutChange = (event, newCurrentLayout) => {
    if (newCurrentLayout !== null) setCurrentLayout(newCurrentLayout);
  };

  function handleRemovedItem(id) {
    var newItems = items.filter((obj) => obj.id !== id);
    onChange(newItems);
  }

  function handleAddItem(item) {
    onChange([...items, item]);
  }

  return (
    <Box className="w-full">
      <Box className="flex justify-between items-center mb-3">
        <Box className="flex gap-2 justify-start items-center">
          <Typography variant="h3" className="pr-4">
            {label}
          </Typography>
          {actions.map((action) => (
            <Button
              onClick={action.onClick}
              color={action.color || "primary"}
              variant={action.variant || "outlined"}
            >
              {action.label}
            </Button>
          ))}
        </Box>

        <ToggleButtonGroup
          value={currentLayout}
          exclusive
          onChange={handleLayoutChange}
          sx={{
            "& .Mui-selected svg": {
              color: colors.greenAccent[500],
            },
          }}
        >
          {layouts.map((layout, index) => (
            <ToggleButton value={index}>{layout.icon}</ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      {createElement(layouts[currentLayout].layout, {
        items,
        ItemComponent: layouts[currentLayout].Item,
        header,
        isLoading,
        onRemoved: handleRemovedItem,
        onAdd: handleAddItem,
      })}
    </Box>
  );
};
